.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
body {
  font-family: "SVNAgencyFB" !important; 
  overflow-x: hidden;
  font-size: 16px !important;
}
.loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}